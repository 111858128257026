import { Router } from 'vue-router';
import wallet from "@/store/wallet";
function loginbycode(to, from, next) {
    let isLogin = wallet.state.isLogin;
    wallet.dispatch('setPath',{
        data:to.fullPath
    });
    document.title = to.meta.title?to.meta.title.zh:'TTS'
    // if(to.meta.auth==false && (to.path !='/' || to.path !='/login')){
    //     next(); 
    // }else{
    //     if(to.path =='/' || to.path =='/login'){
    //         if(isLogin){
    //             next('/home');
    //         }else{
    //             next();
    //         }
    //     }else{
    //         if(isLogin){
    //             next();
    //         }else{
    //             ////为了解决移动端扫码出现重置到首页的异常
    //             if(to.path=='/tuijiandetail'){
    //                 wallet.dispatch('setPathUrl',{
    //                     data: window.location.href
    //                 });
    //             }
    //             next('/login');
    //         }
    //     }
    // }
    next()
}

export default function hook(router: Router): void {
    router.beforeEach(loginbycode)
}
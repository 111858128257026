import request from '@/utils/http';
export function signIn(params: object) {
	return request({
		url: '/tug/login/login',
		method: 'post',
		data: params,
	});
}

export function getUserIdByToken(params: object) {
	return request({
		url: '/ls/user/getUserIdByToken',
		method: 'post',
		data: params,
	});
}
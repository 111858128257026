import {Toast,Button,Empty,Pagination,Icon,Popup,Field} from 'vant';
import Cloading from '@/components/Cloading.vue'
export default function loadComponents(app:any){
    app.use(Toast);
    app.use(Button);
    app.component('c-loading', Cloading)
    app.component('van-empty', Empty)
    app.component('van-pagination', Pagination)
    app.component('van-icon', Icon)
    app.component('van-popup', Popup)
    app.component('van-field', Field)
    //van-pagination
    return app;
}
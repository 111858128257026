import { createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import configureRouter from './configureRouter'
import Page from '@/Page.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect:'/home',
  },
  {
    path: '/metamask',
    component: () =>import(/* webpackChunkName: "metamask" */ '@/views/metamask/index.vue'),
    meta:{
      auth:false,////不登录同样可以访问
      title: {
        zh: "metamask",
        en: "metamask"
      },
      name: 'metamask'
    }
  },
  {
    path:'/page',
    component:Page,
    redirect:'/home',
    name:'Page',
    children:[
      {
        path: '/home',
        name:'home',
        component: () =>import(/* webpackChunkName: "home" */ '@/views/home/home.vue'),
        meta: {
          title: {
            zh: "首页",
            en: "home"
          },
          name: 'home'
        }
      },
      {
        path: '/simu',
        name:'simu',
        component: () =>import(/* webpackChunkName: "home" */ '@/views/simu/index.vue'),
        meta: {
          title: {
            zh: "私募",
            en: "simu"
          },
          name: 'simu'
        }
      },
      {
        path: '/myyaoqing',
        name:'myyaoqing',
        component: () =>import(/* webpackChunkName: "myyaoqing" */ '@/views/myyaoqing/index.vue'),
        meta: {
          title: {
            zh: "我的邀请",
            en: "myyaoqing"
          },
          name: 'myyaoqing'
        }
      },
      {
        path: '/mycenter',
        component: () =>import(/* webpackChunkName: "mycenter" */ '@/views/mycenter/index.vue'),
        name:'mycenter',
        meta: {
          title: {
            zh: "个人中心",
            en: "mycenter"
          },
          name: 'mycenter'
        }
      },
      {
        path: '/rules',
        component: () =>import(/* webpackChunkName: "rules" */ '@/views/rules/index.vue'),
        name:'rules',
        meta: {
          title: {
            zh: "活动规则",
            en: "rules"
          },
          name: 'rules'
        }
      },
    ]
  },
  {
    path: '/noticedetail',
    component: () =>import(/* webpackChunkName: "ziyalog" */ '@/views/notice/noticedetail.vue'),
    name:'noticedetail',
    meta: {
      title: {
        zh: "公告详情",
        en: "noticedetail"
      },
      name: 'noticedetail'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () =>import(/* webpackChunkName: "PageNotFound" */ '@/views/PageNotFound/index.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
})

// Router Hooks
configureRouter(router)

export default router

import * as tp from 'tp-js-sdk'
import {Toast} from 'vant'
import wallet from '@/store/wallet'
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3/dist/web3.min.js';
import TokenWebView from '@consenlabs-fe/webview'
// import router from '@/router'
export default {
    async init() {
        try {
            if(TokenWebView.isTokenEnv()){
                let a:any = window.ethereum;
                window.web3 = new Web3(a);
                await a.enable()
                let accounts = await window.web3.eth.getAccounts();
                console.log('accounts---',window.web3);
                wallet.dispatch('setWallet',{
                    address: accounts[0]
                });
            }else{
                const provider:any = await detectEthereumProvider();
                if (!provider) throw new Error('MetaMask插件尚未安装，请前往官网安装');
                if(provider){
                    const res = await provider.request({ method: 'eth_requestAccounts' });
                    if (!res || res.length <= 0) return
                    const accounts = await provider.request({ method: 'eth_accounts' });
                    wallet.dispatch('setWallet',{
                        address: accounts[0]
                    });
                    provider.on('accountsChanged', function (accounts) {
                        wallet.dispatch('setWallet',{
                            address: accounts[0]
                        });
                    });
                }else{
                    throw new Error('浏览器不支持');
                }
            }
        }
        catch (error:any) {
            Toast(error.message);
        }
    },

    async testInit(debug = false, address = null) {
        if (debug && address) {
            wallet.dispatch('setWallet',{
                address
            });
            return;
        }
        try {
            const isLogin = tp.isConnected()
            if (!isLogin) return
            const res = await tp.getCurrentWallet(1)
            if (!res || !res.result || !res.data){
                return false
            }else{
                wallet.dispatch('setWallet', res.data);
            }
        } catch (error:any) {
            Toast(error.message)
        }
    },
    async sendEthTransactionTp(from, to, value) {
        if (isNaN(parseInt(value))) {
            throw new Error('input illegal')
        }
        try {
            const res = await tp.sendEthTransaction({
                from,
                to,
                gasPrice: 0,
                gasLimit: 60000,
                data: '',
                value
            })
            console.log(res)
        } catch (error:any) {
            Toast(error.message)
        }
    }
}
import request from '@/utils/http';
import {makeQueryString} from '@/utils/tools'

export function getListLogs(params) {
	return request({
		url: '/tug/user/user-relation/list'+makeQueryString(params),
		method: 'get'
	});
}

export function getUserInfo(params) {
	return request({
		url: '/tug/user/getUserIdByToken',
		method: 'post'
	});
}

export function getCoinInfo(params) {
	return request({
		url: '/tug/user/getUserCoinInfo'+makeQueryString(params),
		method: 'get'
	});
}

export function getOnBind(params) {
	return request({
		url: '/tug/user/bind',
		method: 'post',
		data: params,
	});
}

export function getOnTiBi(params) {
	return request({
		url: '/tug/user/withdraw',
		method: 'post',
		data: params,
	});
}
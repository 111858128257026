<template>
  <div style="height: 100%">
    <div class="loader" v-if="loading">
      <div class="loader-inner ball-pulse">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div v-else style="height: 100%">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    loading: Boolean,
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import 'loaders.css/loaders.min.css';
.loader{
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content:center;
  margin-top:10%;
}
.loader-inner{
    div{
      background-color:orange;
    }
  }
.loading-dots {
  position: relative;

  .loading-in {
    height: 70px;
  }

  div {
    position: absolute;
    top: 50px;
    left: 50%;
    width: 8 * $px;
    height: 8 * $px;
    border-radius: 50%;
    background-color: #999;
    opacity: 0.1;
  }
}

.loading-dots.loading-in {
  div {
    animation: twinkle 1000ms infinite forwards;
  }

  div:nth-child(1) {
    transform: translate3d(-20px, 0, 0);
  }

  div:nth-child(2) {
    animation-delay: 250ms;
  }

  div:nth-child(3) {
    transform: translate3d(20px, 0, 0);
    animation-delay: 500ms;
  }
}

@keyframes twinkle {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}
</style>

import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import boot from '@/boot'
import {Lazyload} from 'vant'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import { i18n } from '@/i18n/index';
import store from '@/store/wallet'
import 'vant/lib/index.css';
const app:any = createApp(App)
boot(app)
app.config.globalProperties.$t = i18n.global.t;
app.use(store).use(i18n).use(Lazyload,{lazyComponent:true}).use(router).use(VueClipboard).mount('#app')

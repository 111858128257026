import axios from 'axios';
import {Toast} from 'vant';
import { Session } from '@/utils/storage';

// 配置新建一个 axios 实例
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL as any,
	timeout: 10000,
	headers: {'Accept-Language':Session.get('lang')||'cn'},
});

// 添加请求拦截器
service.interceptors.request.use(
	(config:any) => {
		// 在发送请求之前做些什么 token
		if (Session.get('token')) {
			config.headers.common['token'] = `${Session.get('token')}`;
		}
		return config;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
service.interceptors.response.use(
	(response) => {
		// 对响应数据做点什么
		const res = response.data;
		if (res.status) {
			// `token` 过期或者账号已在别处登录
			if ((res.status === 401) || (res.status === 4001)) {
				Session.clear(); // 清除浏览器全部临时缓存
				Toast.clear()
				Toast({message:'请链接钱包！'});
				return Promise.reject(service.interceptors.response);
			}else if(res.status === 200){
				return response.data;
			}else{
				Toast({
					message:res.data||'服务器异常'
				});
				return Promise.reject(service.interceptors.response);
			}
		} else {
			return Toast({
				message:res.data||'服务器异常'
			});
		}
	},
	(error) => {
		Toast.clear();
		// 对响应错误做点什么
		if (error.message.indexOf('timeout') != -1 || error.message.indexOf('status code 404')) {
			Toast({
				message:'服务器异常'
			});
		} else if (error.message == 'Network Error') {
			Toast({
				message:'服务器异常'
			});
		} else {
			if (error.response.data) Toast({message:error.response.data.error});
			else Toast({message:'接口路径找不到'});
		}
		return Promise.reject(error);
	}
);

// 导出 axios 实例
export default service;
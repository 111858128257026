import { createStore } from 'vuex'
import {Session} from '@/utils/storage'
import {signIn,getUserIdByToken} from '@/api/login'
import router from '@/router/index'
import {getOnBind} from '@/views/mycenter/serve'
export default createStore({
  state: {
    isLogin:Session.get('isLogin')||false,
    userInfo:Session.get('userInfo')||{},
    name:'',
    address:Session.get('addressdata')?(Session.get('addressdata').address||''):'',
    list:[],
    pathurl:"",
    path:""
  },
  mutations: {
    getWallet(state,data){
      state.name = (data || {}).name
      state.address = (data || {}).address
    },
    getWalletList(state,list){
        state.list = list || []
    },
    getlogin(state,isLogin){
      state.isLogin = isLogin || []
    },
    getpathurl(state,data){
      state.pathurl = data || ''
    },
    getpath(state,data){
      state.path = data || ''
    },
    getUserInfo(state,userInfo){
      state.userInfo = userInfo || {}
    }
  },
  actions: {
    async setWallet({ commit,dispatch }, data:any) {
        Session.set('addressdata',data);
        commit('getWallet', data);
        function getQueryString(name) {
          let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
          let r = window.location.search.substr(1).match(reg);
          if (r != null) {
              return unescape(r[2]);
          }
          return null;
      }
      let code:any=null;
      let query = getQueryString('invitCode');
      if(query){
        code = query
      }
      let params:any={
        address: data.address,
        code:code
      }
      const method ='personal_sign';
      const message ='landgame is requesting your sign';
      const msg =message;
      try{
        const ethereum:any = window.ethereum;
        let  signature = await ethereum.request({
          method: method,
          params: [
            msg,
            params.address
          ]
        })
        if(!Session.get('isLogin')){
          params.signature = signature;
          params.message = message;
          let res = await signIn(params);
          if(res.status==200){
            let data = res.data||{};
            let token  = data.token||'';
            Session.set('token',token);
            commit('getlogin', true);
            Session.set('isLogin',true);
            if(code){
              let p = {
                address:code
              }
              getOnBind(p);
            }
            router.push('/home')
          }
        }
      }catch(e){
        console.log(e);
      }
		},
    async setUserInfo({ commit }) {
      let res = await getUserIdByToken({});
      if(res.status==200){
        let userinfo = res.data;
        Session.set('userInfo',userinfo);
        commit('getUserInfo', userinfo);
      }
		},

    async setPathUrl({ commit },data) {
       commit('getpathurl', data.data);
		},   

    async setPath({ commit },data) {
      commit('getpath', data.data);
    },    
    async setWalletList({ commit }, data: object) {
      Session.set('addresslist',data)
			commit('getWalletList', data);
		},
  },
  modules: {},
})
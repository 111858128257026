<template>
  <div class="container">
    <div class="header" :style="{'background-color':'rgba(8,4,1,'+state.titleBarOpacity+')'}">
      <div class="left">
        <img src="@/assets/images/logo.png" alt="">
      </div>
      <div v-if="state.isLogin" class="right notice">
        {{state.address?state.address:'链接钱包'}}
      </div>
      <div v-else class="right notice lianjiebtn" @click="onLinkWallet">
        链接钱包
      </div>
      <div class="lang">
        <img src="@/assets/images/lang.png" alt="">
      </div>
      <div class="right" @click.stop="openMenu">
        <img src="@/assets/images/menu.png" alt="">
        <div class="rightMenu" v-if="state.show">
          <div class="rightMenuBox">
            <span class="sanjiao"></span>
            <ul>
              <li :class="{'active':state.path=='/home'}" @click.stop="gohome('/home')">
                <img src="@/assets/images/11@2x.png" alt=""><span>首页</span>
              </li>
              <li :class="{'active':state.path=='/mycenter'}" @click.stop="gohome('/weikaifang')">
                <img src="@/assets/images/22@2x.png" alt=""><span>质押</span>
              </li>
              <li :class="{'active':state.path=='/simu'}" @click.stop="gohome('/simu')">
                <img src="@/assets/images/33@2x.png" alt=""><span>私募</span>
              </li>
              <li :class="{'active':state.path=='/myyaoqing'}" @click.stop="gohome('/myyaoqing')">
                <img src="@/assets/images/44@2x.png" alt=""><span>我的邀请</span>
              </li>
              <!-- <li @click.stop="onLangChange()">{{locale=='zh'?'english':'中文'}}</li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="body">
      <div :class="state.path=='/manghe'?'routerviewTwo':'routerview'">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
    import { defineComponent,onMounted,reactive,watch} from 'vue';
    import {useRouter,useRoute} from 'vue-router';
    import {useStore} from 'vuex';
    import {Session} from '@/utils/storage'
    import { useI18n } from 'vue-i18n';
    import { Toast,Badge } from 'vant';
    import {formatBankNumber} from '@/utils/tools';
    import tokenPocket from '@/utils/tokenPocket';
    import detectEthereumProvider from '@metamask/detect-provider';
    export default defineComponent({
        components:{
          'van-badge':Badge
        },
        name: "footer",
        setup() {
          const router = useRouter();
          const route = useRoute();
          const store = useStore();

          let state = reactive({
            show:false,
            isLogin:false,
            address:'',
            rgba:1,
            path:'/home',
            titleBarOpacity:0,
          })

          const {locale,t} = useI18n({
            inheritLocale: true,
            useScope: 'global'
          })

          const gohome=(path)=>{
            if(path=='/weikaifang'){
              return Toast({
                message:'该功能尚未开放！',
              })
            }
            state.show = false;
            state.path = path;
            router.push({
              path:path
            })
          }

          const noKaiFang=()=>{
            Toast({
              message:t('t86'),
            });
          }

          const onConnect=()=>{

          }

          const openMenu=()=>{
            state.show = !state.show;
          }

          ///语言切换
          const onLangChange=()=>{
            if(locale.value=='zh'){
              Session.set('lang','en');
              locale.value = 'en'
            }else{
              locale.value = 'zh'
              Session.set('lang','zh');
            }
            state.show = false;
          }

          const onNoticePage=()=>{
            router.push({
              path:'/notice'
            })
          }

          const watchWindowScroll = () => {
            const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop;
            state.titleBarOpacity = scrollHeight / 60
          }

          const onLinkWallet=()=>{
            tokenPocket.init();
          }

          const onlianjie=async()=>{
            const provider:any = await detectEthereumProvider();
            if(provider){
              const res = await provider.request({ method: 'eth_requestAccounts' });
              if (!res || res.length <= 0) return
              const accounts = await provider.request({ method: 'eth_accounts' });
              if(store.state.address!=accounts[0]){
                tokenPocket.init();
                state.address=formatBankNumber(accounts[0],5);
              }
              provider.on('accountsChanged', function (accounts) {
                if(store.state.address!=accounts[0]){
                  tokenPocket.init();
                  state.address=formatBankNumber(accounts[0],5);
                }
              });
            }
          }

          onMounted(async()=>{
            state.isLogin = store.state.isLogin;
            state.address=formatBankNumber(store.state.address,5);
            state.path = route.path; 

            window.addEventListener('scroll', watchWindowScroll)
            onlianjie();
          })

          watch(()=>store.state.path,(n,o)=>{
            if(n!=0){
              console.log('------',n)
              state.path = n;
            }else{
              state.path = o;
            }
          })

          watch(()=>store.state.isLogin,(n,o)=>{
            // alert(store.state.isLogin);
            state.isLogin = store.state.isLogin;
          })


          return {
            gohome,
            openMenu,
            state,
            onLangChange,
            noKaiFang,
            onNoticePage,
            locale,
            t,
            onConnect,
            onLinkWallet
          }
        }
    })
</script>
<style lang="scss" scoped>
@import '@/styles/vars.scss';
.container{
  position: relative;
  width:375*$px;
  margin:0 auto;
}

.leftMenu{
  position:fixed;
  z-index:99999;
  width:100%;
  top:63*$px;
  left:0;
  height:100vh;
  display: flex;
  flex-direction: row;
  .menuBox{
    background-color:#2d2d2d;
    width:80%;
  }
  .menuBoxRight{
    flex:1;
  }
}
.header{
  background-color:transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:0 15*$px;
  position:fixed;
  z-index:999999;
  width:345*$px;
  height:55*$px;
  img{
    width:35*$px;
  }
  .left{
    flex:1;
    display: flex;
    flex-direction: column;
    img{
      width:80*$px;
    }
  }
  .lang{
    display: flex;
    flex-direction: column;
    margin:0 15*$px;
    img{
      width:22*$px;
      height:22*$px;
    }
  }
  .notice{
    border: 1px solid #FFFFFF;
    border-radius: 15*$px;
    font-size: 15*$px;
    font-weight: 500;
    color: #FFFFFF;
    padding:6*$px 11*$px;
  }

  .lianjiebtn{
    width: 123*$px;
    height: 34*$px;
    background:rgba(78,59,127,0.5);
    border-radius: 11*$px;
    padding:0;
    text-align: center;
    line-height:34*$px;
    border:none;
    font-weight: 400;
    font-size: 15*$px;
    color: #FFFFFF;
  }
  .right{
    display: flex;
    flex-direction:column;
    position: relative;
    .rightMenu{
      position: absolute;
      top:0;
      right:0;
      margin-top:42*$px;
      width:130*$px;
      text-align: left;
      ul{
        padding:14*$px;
        padding-bottom:29*$px;
        padding-top:25*$px;
        li{
          img{
            width:22*$px;
            height:22*$px;
            margin-right:6*$px;
          }
        }
      }
    }
  }
}
ul{
  li{
    font-size: 14*$px;
    font-weight:500;
    color: #fff;
    margin-top:28*$px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  li:nth-child(1){
    margin-top:0px;
  }
}
.rightMenuBox{
  background-color:#18140F;
  position: relative;
  border-radius:4*$px;
  .sanjiao{
    position: absolute;
    top:-7*$px;
    right:10*$px;
    display: inline-block;
    border-left: 8*$px solid transparent;
    border-right: 8*$px solid transparent;
    border-bottom: 8*$px solid #18140F;
  }
}
.body{
  .bodybg{
    position: absolute;
    width:100%;
  }
  .routerview{
    position: relative;
    width:100%;
    height:calc(100vh);
  }
  .routerviewTwo{
    position: relative;
    width:100%;
    padding-top:-57.5*$px;
  }
}
.active{
  color:#F6C779;
}
</style>
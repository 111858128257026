import { createI18n } from 'vue-i18n';
import {Session} from '@/utils/storage';
export const i18n = createI18n({
  legacy: false,
  locale: Session.get('lang')||'en',
  messages: {
    en: {
      t1:'Home',
      t2:'LP pool',
      t3:'My community',
      t4:'Pledge',
      t5:'Account logs',
      t6:'my earnings',
      t7:'My assets',
      t8: 'Invite',
      t9: 'Blind Box Quiz',
      t10: "MakerDAO Web3.0 Era",
      t11: "MakerDAO will be built into the most influential decentralized autonomous organization in the Web3.0 era, and by building a strong metaverse ecosystem, it will bring together encrypted people with strong consensus to create a co-construction, co-creation, and co-creation. Governance and sharing circles, and encode MakerDAO's management rights and governance rights on the chain in the form of smart contracts, forming a powerful DAO metaverse autonomous ecology, and everyone will be the beneficiaries of MakerDAO's ecological development.",
      t12: 'Circulation',
      t13: 'Current price',
      t14: 'Destroy pool',
      t15: 'Balance pool',
      t16: 'I also have a bottom line~',
      t17: 'rate of return',
      t18: 'purchase currency',
      t19: 'Buy',
      t20: "Today's fee distribution",
      t21: 'Number pledges',
      t22: 'release ratio',
      t23: 'the term',
      t24: 'day',
      t25: 'shareholders',
      t26: 'indivual',
      t27: 'Join record',
      t28: 'Earnings',
      t29: 'load more',
      t30: 'no data',
      t31: 'No description yet',
      t32: 'LP level',
      t33: 'Personal power',
      t34: 'Community power',
      t35: 'member',
      t36: 'grade',
      t37: 'performance',
      t38: 'Amount:',
      t39: 'Rate of return:',
      t40: 'Earned income:',
      t41: 'Ransom',
      t42: 'quantity',
      t43: 'fee',
      t44: 'date',
      t45: 'handling fee',
      t46: 'Sure',
      t47: 'Withdrawal amount',
      t48: 'all',
      t49: 'inLine',
      t50: 'running',
      t51: 'redemption',
      t52: 'redeemed',
      t53: 'static income',
      t54: 'Dynamic income',
      t55: 'Current total assets',
      t56: 'Available',
      t57: 'Folded',
      t58: 'Deposit',
      t59: 'Withdraw',
      t60: 'Deposit amount',
      t61: 'History Record',
      t62: 'Recharge record',
      t63: 'Withdrawals',
      t64: "Tell your friends you're using MakerDAO",
      t65: 'Come to a reliable platform to earn together',
      t66: 'win at this turning point',
      t67: 'Save QR',
      t68: 'sponsored links:',
      t69: 'Copy',
      t70: 'Recommend 1 valid address, take 1 generation',
      t71: 'Recommend 3 valid addresses, take 3 generations',
      t72: 'Recommend 5 valid addresses, get unlimited generation (4th generation to infinite generation)',
      t73: '(50% from 20% from the sixth generation to infinite generation)',
      t74: 'generation',
      t75: 'Withdraw',
      t76: 'Withdrawal currency',
      t77: 'chain name',
      t78: 'Withdrawal address',
      t79: 'Withdrawal amount',
      t80: 'all',
      t81: 'Miner fee:',
      t82: 'Available:',
      t83: 'expected to arrive',
      t84: 'Minimum withdrawal amount',
      t85: 'onBack',
      t86: 'Not yet open!',
      t87: 'The quantity format is wrong!',
      t88: 'Successful operation!',
      t89: 'operation failed!',
      t90: "Purchase quantity",
      t91: 'Pay for:',
      t92: 'Link Award',
      t93: 'Community Awards',
      t94: 'Fee dividends',
      t95: 'shareholder release',
      t96: 'You have already joined, you cannot join again!',
      t97: 'confirming',
      t98: 'success',
      t99: 'fail',
      t100: 'Data format error!',
      t101: 'successful recharge',
      t102: 'Extraction successful!',
      t103: 'Insufficient balance!',
      t104: 'The minimum amount of coins to be withdrawn is',
      t105: 'Spend 360 MDAO to draw a blind box',
      t106: 'MY NFT',
      t107: 'lottery record',
      t108: '(Note: The types of rewards are mainly divided into N, R, SR, SSR grades in increasing order)',
      t109: 'Congratulations on getting',
      t110:'level reward',
      t111:'value',
      t112:'lottery record',
      t113:'grade',
      t114:'Install metamask plugin',
      t115:'Hello! Please re-select wallet login',
      t116:'try again',
      t117:'Congratulations',
      t118:'get',
      t119:' level value',
      t120:'total power',
      t121:'joined',
      t122:'not joined',
      t123:"Income balance",
      t124:'Pledge quantity',
      t125:'unleash revenue',
      t126:'withdraw',
      t127:"Time",
      t128:'Cancel',
      t129:'Please enter the withdrawal amount',
      t130:'Balance:',
      t131:'The format of the withdrawal amount is incorrect!',
      t132:"The withdrawal amount has exceeded the maximum balance!",
      t133:"Withdrawal succeeded!",
      t134:'Withdrawal failed!'
    },
    zh: {
        t1:'首页',
        t2:'LP池',
        t3:'我的社区',
        t4:'质押记录',
        t5:'账本明细',
        t6:'我的收益',
        t7:'我的资产',
        t8: '邀请',
        t9: '盲盒竞猜',
        t10: 'MakerDAO Web3.0时代',
        t11: 'MakerDAO将打造成Web3.0时代中最具影响力的去中心化自治组织，并通过构建强大的元宇宙生态体系，把拥有强大共识的加密人士组合在一起，打造一个共建、共创、共治、共享的圈子，并把MakerDAO的管理权与治理权以智能合约的形式编码在链上，形成一个强大的DAO元宇宙自治生态，人人都将是MakerDAO生态发展的受益者。',
        t12: '总发行量',
        t13: '当前价格',
        t14: '销毁池',
        t15: '平衡池',
        t16: '我也是有底线的～',
        t17: '收益率',
        t18: '购买币种',
        t19: '购买',
        t20: '今日手续费分红',
        t21: '质押数',
        t22: '释放比例',
        t23: '期限',
        t24: '天',
        t25: '当前股东数',
        t26: '个',
        t27: '股东加入记录',
        t28: '收益记录',
        t29: '加载更多',
        t30: '暂无数据',
        t31: '暂无描述',
        t32: 'LP等级',
        t33: '个人质押算力',
        t34: '小区质押算力',
        t35: '成员',
        t36: '等级',
        t37: '业绩',
        t38: '金额：',
        t39: '收益率：',
        t40: '已产收益：',
        t41: '赎回',
        t42: '赎回数量',
        t43: '赎回手续费',
        t44: '赎回日期',
        t45: '手续费',
        t46: '确定',
        t47: '请输入提币数量',
        t48: '全部',
        t49: '排队中',
        t50: '运行中',
        t51: '赎回中',
        t52: '已赎回',
        t53: '静态收益',
        t54: '动态收益',
        t55: '当前总资产',
        t56: '可用',
        t57: '折合',
        t58: '充币',
        t59: '提币',
        t60: '充币数量',
        t61: '历史记录',
        t62: '充值记录',
        t63: '提现记录',
        t64: '告诉好友你在用MakerDAO',
        t65: '来靠谱的平台一起赚',
        t66: '赢在这个转折点',
        t67: '保存二维码到相册',
        t68: '推广链接：',
        t69: '复制',
        t70: '推荐1个有效地址，拿1代',
        t71: '推荐3个有效地址，拿3代',
        t72: '推荐5个有效地址，拿无限代（第4代到无限代）',
        t73: '（从第六代开始20%的50%递减到无限代）',
        t74: '代',
        t75: '提币',
        t76: '提币币种',
        t77: '链名称',
        t78: '提币地址',
        t79: '提币数量',
        t80: '全部',
        t81: '矿工费：',
        t82: '可用：',
        t83: '预计到账',
        t84: '请输入提币数量最少',
        t85: '返回',
        t86: '暂未开放！',
        t87: '数量格式有误！',
        t88: '操作成功！',
        t89: '操作失败！',
        t90: "购买数量",
        t91: '需支付：',
        t92: '链接奖',
        t93: '社区奖',
        t94: '手续费分红',
        t95: '股东释放',
        t96: '您已加入，无法重复加入！',
        t97: '确认中',
        t98: '成功',
        t99: '失败',
        t100: '数据格式错误！',
        t101: '充值成功',
        t102: '提取成功！',
        t103: '余额不足！',
        t104: '提币数量最少为',
        t105: '花费360枚MDAO抽一次盲盒',
        t106: '我的NFT',
        t107: '抽奖记录',
        t108: '（备注：奖励类型依次递增主要分为N、R、SR、SSR等级）',
        t109:'恭喜获取',
        t110:'级奖励',
        t111:'价值',
        t112:'抽奖记录',
        t113:'等级',
        t114:'安装metamask插件',
        t115:'您好！请重新选择钱包登录',
        t116:'再试一次',
        t117:'恭喜',
        t118:'获得',
        t119:'级价值',
        t120:'总算力',
        t121:'已加入',
        t122:'未加入',
        t123:"收益余额",
        t124:'质押数量',
        t125:'释放收益',
        t126:'提现',
        t127:"时间",
        t128:'取消',
        t129:'请输入提现数量',
        t130:'可以提现余额：',
        t131:'提现数量格式错误！',
        t132:"提现数量已超过最大余额！",
        t133:"提现成功！",
        t134:'提现失败！'
      }
    }
})

import 'vant/lib/style/base.less';
import '@/styles/base.scss';
(function (w, d) {
  const root = d.documentElement
  function getFontSizeScaleRate() {
    try {
      if (window.innerWidth >= 768) {
        // 以768px为界限，超过该宽度不放大字体
        return 1
      }

      if (/(iphone|ipad|ipod)/i.test(window.navigator.userAgent)) {
        return 1
      }

      const $dom = document.createElement('div')
      $dom.setAttribute('style', 'font-size:12px;')
      document.body.appendChild($dom)
      // 计算出放大后的字体
      const scaledFontSize = parseFloat(
        window.getComputedStyle($dom, null).getPropertyValue('font-size'),
      )
      document.body.removeChild($dom)
      // 计算原字体和放大后字体的比例
      return 12 / scaledFontSize
    } catch (error) {
      return 1
    }
  }
  function setRootFontSize() {
    let screenWidth = root.clientWidth
    screenWidth = Math.min(540, Math.max(240, screenWidth)) // 限制最小和最大屏幕宽度
    const baseFontSize = screenWidth / 18.75
    root.style.fontSize = baseFontSize * getFontSizeScaleRate() + 'px'
  }

  function setBodyFontSize() {
    if (d.body) {
      d.body.style.fontSize = 16 * getFontSizeScaleRate() + 'px'
    } else {
      d.addEventListener('DOMContentLoaded', setBodyFontSize)
    }
  }
  setBodyFontSize()
  setRootFontSize()
  w.addEventListener('resize', function () {
    setRootFontSize()
  })
  w.addEventListener('pageshow', function () {
    setRootFontSize()
  })
})(window, document)

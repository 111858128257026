export function formatBankNumber(bankNumber:string,num:number){
    return bankNumber.substr(0,num)+"*****"+bankNumber.substr(-num);
}

/**
 * 对象是否为空
 * @param obj
 * @return {boolean}
 */
export const isEmpty = (obj, strict = false) => {
  if (obj === null || obj === undefined)  return true;
  if (typeof obj === 'number') return false
  if (typeof obj === 'string') {
    if (strict) {
      return obj.length <= 0;
    }
    return false;
  }
  if (typeof obj === 'object') {
    if (strict) {
      return Object.keys(obj).length <= 0
    }
    return false
  }
  if (obj === undefined) return true;
  if (obj === null) return true;
  if (isNaN(obj)) return true;
  return false
};

export const makeQueryString = (obj:any) => {
  if (!obj) return '';
  const query = Object.keys(obj).filter(k => Object.prototype.hasOwnProperty.call(obj, k) && !isEmpty(obj[k], true)).map(k => `${k}=${obj[k]}`).join('&');
  if (isEmpty(query)) {
    return ''
  }
  return `?${query}`
};

/**
 * @description 复制
 * @param {*} id DOM ID
 */
export function copyDomText (id) {
  const node = document.getElementById(id);
  if (node) {
    let createRange = document.createRange()
    createRange.selectNodeContents(node)
    const selection:any = document.getSelection()
    selection.removeAllRanges()
    selection.addRange(createRange)
    document.execCommand('Copy')
    selection.removeAllRanges()
    alert('已复制')
  }
}

export const makeFormData = (params) => {
  if (!params) return new FormData();
  if (typeof params !== "object") return new FormData();
  let formData = new FormData();
  Object.keys(params).forEach(k => {
    if (Object.prototype.hasOwnProperty.call(params, k) && !isEmpty(params[k])) {
      ///如果需要添加到表单的值是数组
      if (Array.isArray(params[k]) && !isEmpty(params[k])) {

        params[k].forEach((i, index) => {

          ///如果是文件 直接添加到formDate
          if (i.constructor === File) {
            formData.append(k, i)
          }
          ///如果是对象 需要转换为 form字段[数组下标].对象的key 这个形式的key再添加到formData
          else if (typeof i === 'object') {
            Object.keys(i).forEach(kk => {
              formData.append(`${k}[${index}].${kk}`, i[kk]);
            });
          } else {
            formData.append(k, i)
          }
        });
      }
      ///如果需要添加到表单的是对象
       else if(params[k].constructor === File){
        formData.append(k,params[k]);
      }
      else if (typeof params[k] === 'object') {
        Object.keys(params[k]).forEach(kk => {
          formData.append(`${k}.${kk}`, params[k][kk]);
        });
      } else {
        formData.append(k, params[k]);
      }
    }
  });
  return formData;
};

export function toChineseNumeral (num) {
    var numerals = {"-":"负", ".":"点", 0:"零", 1:"一", 2:"二", 3:"三", 4:"四", 5:"五",
      6:"六",  7:"七", 8:"八", 9:"九", 10:"十", 100:"百", 1000:"千",10000:"万" };
      // 如果num为负数
    if (num < 0) {
      return numerals['-'] + toChineseNumeral(-num);
      // num 为 0或0点几的小数
    } else if (num < 1) {
      return num.toString().split('').reduce(function(p, n) {
        return p + numerals[n];
      }, '');
    } else if (num > Math.floor(num)) {
      return toChineseNumeral(Math.floor(num)) + toChineseNumeral(parseFloat(num.toString().replace(/^.*\./, '0.'))).slice(1);
    } else {
      return [10000, 1000, 100, 10, 1].reduce(function(p, n) {
        if (num >= n) {
          if (p.ling) p.ch += numerals[0];
          p.ch += numerals[Math.floor(num / n)];
          if (n != 1) p.ch += numerals[n];
          p.ling = false;
        } else if (p.ch) {
          p.ling = true;
        }
        num %= n;
        return p;
      }, {ch: '', ling: false}).ch.replace(/^一十/, '十');
    }
}
